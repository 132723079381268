<!-- textBefore -->
<template>
  <div class="textBefore">
    <el-form :model="ruleForm" :rules="rules">
      <el-form-item :label="infoLabel" prop="textBefore" v-if="showTab">
        <el-tabs type="border-card" v-model="textBeforeActiveName">
          <el-tab-pane label="文本框" name="textBeforeOne">
            <el-input autosize class="min-height-textarea" v-if="textBeforeActiveName === 'textBeforeOne'" v-model="ruleForm.textBefore" type="textarea" />
          </el-tab-pane>
          <el-tab-pane label="富文本" name="textBeforeTwo">
            <editor
              v-if="textBeforeActiveName === 'textBeforeTwo'"
              tinymce-script-src="../../../../../js/tinymce/tinymce.min.js"
              :init="init"
              model-events="change keydown blur focus paste"
              v-model="ruleForm.textBefore"
            />
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
      <el-form-item prop="textBefore" label="期刊介绍：" v-else>
        <editor v-model="ruleForm.textBefore" :tinymce-script-src="tinymceSrc" :init="init" model-events="change keydown blur focus paste" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import {uploadEditorImg,getPictureStreamPath} from "@/api/configManage"
export default {
  name: 'textBefore',
  data() {
    return {
      uploadEditorImg,
      init: {
        auto_focus: true,
        min_height: 400,
        menubar: false,
        language: 'zh_CN',
        skin: 'CUSTOM',
        content_css: 'CUSTOM',
        convert_urls:false,
        plugins:
          'quickbars print preview searchreplace autolink directionality visualblocks visualchars fullscreen template code codesample charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help link emoticons autosave indent2em lineheight formatpainter image',
        toolbar:
          'fontselect fontsizeselect bullist numlist outdent indent indent2em formatselect  formatpainter bold italic underline strikethrough superscript subscript removeformat forecolor backcolor alignleft aligncenter alignright alignjustify lineheight link undo redo image',
        toolbar_mode: 'sliding',
        quickbars_selection_toolbar: 'bold italic underline strikethrough subscript superscript removeformat',
        quickbars_insert_toolbar: false,
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        font_formats:
          '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;宋体=simsun,serif,sans-serif;仿宋体=FangSong,serif,sans-serif;楷体=KaiTi,serif,sans-serif;黑体=SimHei,sans-serif;隶书=LiSu,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Times New Roman=Times New Roman,helvetica,sans-serif;',
        images_upload_handler: (blobInfo, success) => {
          this.upload(blobInfo,(e)=>{
            success(e)
          })
        }
      },
      textBeforeActiveName: 'textBeforeOne',
      tinymceSrc: '../../../../../js/tinymce/tinymce.min.js'
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showTab: {
      type: Boolean,
      default: true
    },
    infoLabel: {
      type: String,
      default: ''
    }
  },
  components: { Editor },
  computed: {},
  mounted() {
  },
  methods: {
    upload(blobInfo,fn) {
      let formData = new FormData()
      formData.append('file', blobInfo.blob())
      uploadEditorImg(formData).then(res => {
        if (res.data.status === 0) {
          getPictureStreamPath({pictureId: res.data.data.ID }).then(res1 => {
            let url = res1.data.data.url 
            fn && fn(url) 
          })
        } else {
          this.$message.error('图片上传失败')
          fn && fn('')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
