<!-- column -->
<template>
  <div class="column">
    <el-form :model="ruleForm" :rules="rules">
      <el-form-item label="目录标题：" prop="column">
        <el-input class="column-input" v-model="ruleForm.column" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'column',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
