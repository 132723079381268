<!-- type11Template -->
<template>
  <div class="type11Template">
    <el-form :model="ruleForm" :rules="rules">
      <textBefore :ruleForm="ruleForm" :rules="rules" infoLabel="文前内容："></textBefore>
      <textAfter :ruleForm="ruleForm" :rules="rules" infoLabel="文后内容："></textAfter>
      <!-- <openPublishPush v-if="configInfo.openPublishPush" :ruleForm="ruleForm"></openPublishPush> -->
      <!-- <openReferencePush v-if="configInfo.openReferencePush" :ruleForm="ruleForm"></openReferencePush> -->
    </el-form>
  </div>
</template>

<script>
import textBefore from '../modules/textBefore.vue'
import textAfter from '../modules/textAfter.vue'
import openPublishPush from '../modules/openPublishPush.vue'
import openReferencePush from '../modules/openReferencePush.vue'
export default {
  name: 'type11Template',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    },
    configInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { textAfter, textBefore },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
