<!-- scholarAnalysis -->
<template>
  <div class="w center_all scholarAnalysis">
    <div class="center_2" v-loading="loading" element-loading-text="正在加载，请稍后...">
      <div class="header">
        <ul class="crumbs clearfix">
          <li>邮件推送</li>
          <li>-></li>
          <li>任务管理</li>
          <li>-></li>
          <li>学者管理</li>
        </ul>
      </div>
      <div class="report-content" v-if="showMBYHSFLY">
        <div class="page-part">
          <div class="echarts-first-title">
            邮件传播——目标用户算法来源分布
            <div class="version-wrapper">
              推送版本
              <el-select clearable v-model="selectedDateVal" @change="changeVersion">
                <el-option v-for="(item, idx) in sendVersionOptions" :key="idx" :label="item.date" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="echarts-left-title mb-20">
            目标用户<span>{{ userDataObj.countPushMagAuthor || 0 }}人</span>
          </div>
          <SimpleTable ref="MBYHLYNewTable" no-pagination noSerial showBorder :table-data="MBYHLYNewTableData" :column-data="MBYHLYNewColumnData" />
        </div>
      </div>
      <div class="download-btn" @click="printOut(currentTask.topic)" :class="isDisabled ? 'download-btn-disabled' : ''">
        <img v-if="isDisabled" :src="downloadReportDisabledImg" alt="" />
        <img v-else :src="downloadReportImg" alt="" />
        下载报告
      </div>
      <div class="report-content" id="reportContent">
        <div class="page1 page-part flex-content">
          <div class="title">{{ currentTask.topic }}<br />邮件推送目标学者分析</div>
        </div>
        <div class="page2 page-part">
          <div class="echarts-first-title">发文分析</div>
          <div class="echarts-second-title">引用期刊分布（近五年，Top15）</div>
          <div
            id="chartYYQK"
            :style="{
              width: '100%',
              height: getTableContentHeight('page2', false, true)
            }"
          />
          <div class="position-bottom">
            <div class="echarts-line">
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line1"></div>
            </div>
            <div class="bottom-info">
              <div class="bottom-info-left">数据统计时间：2016年至制图日</div>
              <div class="bottom-info-right">
                制图说明：统计{{ currentTask.topic }}近五年来所引用文章的期刊来源，图中从左向右展示引用次数由高到低的前15个引用来源期刊，虚线连接的数据点为这些期刊当前的影响因子（知网复合影响因子）。
              </div>
            </div>
          </div>
        </div>
        <div class="page3 page-part">
          <div class="echarts-first-title">发文分析</div>
          <div class="echarts-second-title">引证期刊分布（近五年，Top15）</div>
          <div
            id="chartYZQK"
            :style="{
              width: '100%',
              height: getTableContentHeight('page3', true, true)
            }"
          />
          <div class="position-bottom">
            <div class="echarts-line">
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line1"></div>
            </div>
            <div class="bottom-info">
              <div class="bottom-info-left">数据统计时间：2016年至制图日</div>
              <div class="bottom-info-right">
                制图说明：统计{{
                  currentTask.topic
                }}近五年来被引用文章的发文期刊分布，图中从左向右展示被引用次数由高到低的前15个被引用期刊，虚线连接的数据点为这些期刊当前的影响因子（知网复合影响因子）。
              </div>
            </div>
          </div>
        </div>
        <div class="page4 page-part">
          <div class="echarts-first-title">发文分析</div>
          <div class="table-content" :style="getTableContentHeight('page4', false, false)">
            <div class="table-content-box">
              <div class="echarts-second-title">
                本刊机构发文统计（近五年，Top10）
              </div>
              <SimpleTable ref="JGFWTJTable" no-pagination showBorder :table-data="JGFWTJTableData" :column-data="JGFWTJColumnFive" />
            </div>
          </div>
          <div class="position-bottom">
            <div class="echarts-line">
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line1"></div>
            </div>
            <div class="bottom-info">
              <div class="bottom-info-left">数据统计时间：2016年至制图日</div>
              <div class="bottom-info-right">
                制图说明：统计{{ currentTask.topic }}近五年来发表文章作者的所在机构，图中依次展示发文数量由高到低的前10个机构，该机构为细化到学院或实验室等具有一定学科方向的机构。
              </div>
            </div>
          </div>
        </div>
        <div class="page5 page-part">
          <div class="echarts-first-title">发文分析</div>
          <div class="table-content" :style="getTableContentHeight('page5', false, false)">
            <div class="table-content-box">
              <div class="echarts-second-title">
                引用机构分布（近五年，Top10）
              </div>
              <SimpleTable ref="YYJGTable" no-pagination showBorder :table-data="YYJGTableData" :column-data="YYJGColumnFive" />
            </div>
          </div>
          <div class="position-bottom">
            <div class="echarts-line">
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line1"></div>
            </div>
            <div class="bottom-info">
              <div class="bottom-info-left">数据统计时间：2016年至制图日</div>
              <div class="bottom-info-right">
                制图说明：统计{{ currentTask.topic }}近五年来引用文章作者的所在机构，图中依次展示发文数量由高到低的前10个机构，该机构为细化到学院或实验室等具有一定学科方向的机构。
              </div>
            </div>
          </div>
        </div>
        <div class="page6 page-part">
          <div class="echarts-first-title">发文分析</div>
          <div class="table-content" :style="getTableContentHeight('page6', false, false)">
            <div class="table-content-box">
              <div class="echarts-second-title">
                引证机构分布（近五年，Top10）
              </div>
              <SimpleTable ref="YZJGTable" no-pagination showBorder :table-data="YZJGTableData" :column-data="YZJGColumnFive" />
            </div>
          </div>
          <div class="position-bottom">
            <div class="echarts-line">
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line1"></div>
            </div>
            <div class="bottom-info">
              <div class="bottom-info-left">数据统计时间：2016年至制图日</div>
              <div class="bottom-info-right">
                制图说明：统计{{ currentTask.topic }}近五年来被引用文章作者的所在机构，图中依次展示发文数量由高到低的前10个机构，该机构为细化到学院或实验室等具有一定学科方向的机构。
              </div>
            </div>
          </div>
        </div>
        <div class="page7 page-part">
          <div class="echarts-first-title">邮件传播——目标用户概览</div>
          <div class="situation">
            <div class="situation-info">
              <div class="title">目标用户情况</div>
              <div class="info">
                <span class="text-box">
                  目标用户人数
                  <span>
                    {{ userDataObj.countPushMagAuthor || 0 }}
                  </span>
                </span>
                <span class="text-box ml-percent16">
                  白名单人数
                  <span>
                    {{ userDataObj.countPushMagAuthorWrite || 0 }}
                  </span>
                </span>
                <span class="text-box mt-percent16">
                  机构覆盖量
                  <span>
                    {{ userDataObj.countPushMagAuthorOrg || 0 }}
                  </span>
                </span>
                <span class="text-box ml-percent16 mt-percent16">
                  期刊覆盖量
                  <span>
                    {{ userDataObj.countPushMagAuthorMag || 0 }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="page8 page-part">
          <div class="echarts-first-title">邮件传播——目标用户来源分布</div>
          <div class="echarts-left-title">
            目标用户<span>{{ userDataObj.countPushMagAuthor || 0 }}人</span>
          </div>
          <div
            id="chartMBYHSFLY"
            :style="{
              width: '100%',
              height: getTableContentHeight('page8', true, true)
            }"
          />
          <div class="position-bottom">
            <div class="echarts-line">
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line1"></div>
            </div>
            <div class="bottom-info" v-html="MBYHLYBottomInfo"></div>
          </div>
        </div>

        <div class="page9 page-part" v-show="isIssue && issueColumnList.length > 0" :class="`page9-${index}`" v-for="(item, index) in issueColumnList" :key="index">
          <div class="echarts-first-title">邮件传播——目标用户来源分布</div>
          <div class="echarts-left-title">
            <span>{{ item.column }}-</span>目标用户<span>{{ item.count || 0 }}人</span>
          </div>
          <div class="table-content" :style="getTableContentHeight(`page9-${index}`, true, false)">
            <div class="table-content-box">
              <div class="echarts-second-title">
                目标用户来源分布
              </div>
              <SimpleTable no-pagination noSerial showBorder :table-data="item.data" :column-data="item.columnData" />
            </div>
          </div>
          <div class="position-bottom">
            <div class="echarts-line">
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line1"></div>
            </div>
            <div class="bottom-info">
              制图说明：表中展示的目标用户分布是通过分析该栏目得到的，各部分含义如下：<br />
              （1）论文作者包括本栏目论文作者，及其他相关论文作者<br />
              （2）关键词匹配作者，表示与本栏目论文具有相同关键词的其他论文的作者<br />
              （3）机构学者是从发文机构的维度构建的推送用户群体，包括本栏目论文作者同机构的学者，及其他相关机构学者
            </div>
          </div>
        </div>
        <div class="page10 page-part">
          <div class="echarts-first-title">邮件传播——目标用户机构分析</div>
          <div class="echarts-left-title">
            目标用户覆盖机构<span>{{ userDataObj.countPushMagAuthorOrg || 0 }}家</span>
          </div>
          <div class="echarts-second-title">
            目标用户机构分布（Top20）
          </div>
          <div
            id="chartMBYHJG"
            :style="{
              width: '100%',
              height: getTableContentHeight('page10', true, true)
            }"
          />
          <div class="position-bottom">
            <div class="echarts-line">
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line1"></div>
            </div>
            <div class="bottom-info">
              制图说明：说明{{ currentTask.topic }}邮件推送目标用户的所在机构，图中依次展示人数由高到低的前20个机构。本次推送目标用户覆盖机构{{ userDataObj.countPushMagAuthorOrg || 0 }}家。
            </div>
          </div>
        </div>
        <div class="page11 page-part">
          <div class="echarts-first-title">邮件传播——目标用户期刊分析</div>
          <div class="echarts-left-title">
            目标用户期刊覆盖量<span>{{ userDataObj.countPushMagAuthorMag || 0 }}本刊</span>
          </div>
          <div class="echarts-second-title">
            目标用户期刊分布（Top20）
          </div>
          <div
            id="chartMBYHQK"
            :style="{
              width: '100%',
              height: getTableContentHeight('page11', true, true)
            }"
          />
          <div class="position-bottom">
            <div class="echarts-line">
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line1"></div>
            </div>
            <div class="bottom-info">
              制图说明：说明{{ currentTask.topic }}邮件推送目标用户的期刊分布，体现了本次目标用户发表文章的期刊分布情况，图中依次展示人数由高到低的前20个期刊。本次推送目标用户期刊覆盖量{{
                userDataObj.countPushMagAuthorMag || 0
              }}本刊。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPdf } from '@/assets/js/htmlToPdf.js'
import {
  ReferenceMag,
  CitationMag,
  Institution,
  ReferenceInstitution,
  CitationInstitution,
  GetPushMagAuthorNumber,
  GetPushMagAuthorOrg,
  GetPushMagAuthorMag,
  GetPushMagAuthorType,
  GetColumn,
  GetPushMagAuthorTypeByColumn,
  GetPushMagAuthorSourceType,
  GetPushMagAuthorSetCount,
  getSendVersion
} from '@/api/scholarAnalysis'
import SimpleTable from '@/components/SimpleTable'
import { getRecentYears } from '@/utils'
export default {
  name: 'scholarAnalysis',
  data() {
    return {
      loading: true,
      noImg: require('@/assets/img/default.png'),
      downloadReportImg: require('@/assets/img/download-report.png'),
      downloadReportDisabledImg: require('@/assets/img/download-report-disabled.png'),
      isDisabled: false,
      basicInfo: {},
      currentTask: {},
      JGFWTJTableData: [],
      JGFWTJColumnFive: [
        {
          title: '发文机构',
          prop: 'institutionName',
          width: '150px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title', '', '', 'notSortable'),
        {
          title: '合计',
          prop: 'totalCount5',
          notSortable: true
        },
        {
          title: '平均被引',
          prop: 'referedCount5',
          notSortable: true
        },
        {
          title: '单篇最高被引',
          width: '130px',
          prop: 'singleReferedCount5',
          notSortable: true
        }
      ],
      YYJGTableData: [],
      YYJGColumnFive: [
        {
          title: '引用机构',
          prop: 'institutionName',
          width: '190px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title', '', '', 'notSortable'),
        {
          title: '合计',
          prop: 'totalCount5',
          notSortable: true
        }
      ],
      YZJGTableData: [],
      YZJGColumnFive: [
        {
          title: '引证机构',
          prop: 'institutionName',
          width: '190px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title', '', '', 'notSortable'),
        {
          title: '合计',
          prop: 'totalCount5',
          notSortable: true
        }
      ],
      MBYHLYColumnData: [
        {
          title: '来源',
          notSortable: true,
          prop: 'source',
          width: '50px'
        }
      ],
      MBYHLYTableData: [],
      MBYHLYNewColumnData: [
        {
          title: '学者来源',
          notSortable: true,
          prop: '学者来源'
        },
        {
          title: '论文范围',
          notSortable: true,
          prop: '论文范围'
        },
        {
          title: '学者行为',
          notSortable: true,
          prop: '学者行为'
        },
        {
          title: '学者特征',
          notSortable: true,
          prop: '学者特征'
        },
        {
          title: '人数',
          notSortable: true,
          prop: '人数'
        }
      ],
      MBYHLYNewTableData: [],
      userDataObj: {},
      issueColumnList: [],
      showMBYHSFLY: true,
      selectedDateVal: '',
      sendVersionOptions: []
    }
  },
  props: {},
  components: { SimpleTable },
  computed: {
    ...mapGetters({
      magId: 'magId'
    }),
    // 是刊维度
    isPeriodical() {
      return this.currentTask.pushType == 1 || this.currentTask.pushType == 4
    },
    // 是期维度
    isIssue() {
      return this.currentTask.pushType == 5 || this.currentTask.pushType == 6
    },
    MBYHLYBottomInfo() {
      let PeriodicalInfo = `制图说明：说明${this.currentTask.topic}的发文情况进行分析，从期刊和发文机构两个维度构建推送用户群体。<br />
              （1）本刊白名单为刊社提供的用户信息<br />
              （2）本刊作者、引用刊作者和引证刊作者是从期刊维度构建的推送用户群体，分别表示本刊中的文章作者、引用期刊中的文章作者、引证期刊（本刊文章被引期刊）中的文章作者<br />
              （3）本刊机构学者、引用刊机构学者和引证刊作者所在机构的其他学者用户（按照高发文、高引证、高引用获取学者信息）<br />
              （4）本领域学者表示，通过当期发文的研究领域、关键词，在WOS等专业网站和数据库中获得的推送用户数据`
      let IssueInfo = `制图说明：通过分析本期推送论文，从期刊和发文机构两个维度构建推送用户群体：<br />
                （1）本刊编委<br />
                （2）本刊白名单为编辑部老师提供的用户信息<br />
                （3）论文作者包括本期论文作者，及其他相关论文作者<br />
                （4）关键词匹配学者，表示与本栏目论文具有相同关键词的其他论文的作者<br />
                （5）机构作者是从发文机构的维度构建的推送用户群体，包括本期论文作者同机构学者，及其他相关机构学者`
      return this.isPeriodical ? PeriodicalInfo : this.isIssue ? IssueInfo : ''
    }
  },
  mounted() {
    this.currentTask = JSON.parse(sessionStorage.getItem('emailCurrentTask'))
    window.addEventListener('resize', this.adjustTable)
    this.$nextTick(() => {
      if (this.magId) {
        this.getData()
      }
      this.getSendVersion()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustTable)
  },
  methods: {
    // 窗口大小改变重新渲染echarts
    adjustTable() {
      if (document.getElementById('chartYYQK')) {
        this.$echarts.init(document.getElementById('chartYYQK')).resize()
      }
      if (document.getElementById('chartYZQK')) {
        this.$echarts.init(document.getElementById('chartYZQK')).resize()
      }
      if (document.getElementById('chartMBYHJG')) {
        this.$echarts.init(document.getElementById('chartMBYHJG')).resize()
      }
      if (document.getElementById('chartMBYHQK')) {
        this.$echarts.init(document.getElementById('chartMBYHQK')).resize()
      }
    },
    // 计算表格内容高度使其居中
    getTableContentHeight(dom, haveLeftTitle, isEcharts) {
      this.$nextTick(() => {
        let computedHeight = 0
        let childrenList = document.getElementsByClassName(dom)[0].children
        for (var i = 0; i < childrenList.length; i++) {
          if (isEcharts) {
            if (childrenList[i].className) {
              computedHeight = computedHeight + childrenList[i].clientHeight
            }
          } else {
            if (childrenList[i].className !== 'table-content') {
              computedHeight = computedHeight + childrenList[i].clientHeight
            }
          }
        }
        let fanalHeight = 525 - computedHeight - (haveLeftTitle ? 20 : 0)
        for (var j = 0; j < childrenList.length; j++) {
          if (isEcharts) {
            if (!childrenList[j].className) {
              childrenList[j].style.height = fanalHeight + 'px'
            }
          } else {
            if (childrenList[j].className === 'table-content') {
              childrenList[j].style.height = fanalHeight + 'px'
            }
          }
        }
      })
    },
    // 下载报告
    printOut(name) {
      if (this.isDisabled) {
        return
      }
      this.showMBYHSFLY = false
      this.$nextTick(() => {
        let pdfWidth = document.getElementsByClassName('page1')[0].clientWidth
        let pdfHeight = document.getElementsByClassName('page1')[0].clientHeight
        console.log(pdfWidth, pdfHeight, '000')
        getPdf('reportContent', name, pdfWidth, pdfHeight)
        setTimeout(() => {
          this.showMBYHSFLY = true
          this.getPushMagAuthorSetCount()
        }, 1000)
      })
    },
    getData(versionUUid) {
      Promise.all([
        // 引用期刊分布
        this.getChartData(
          'YYQK',
          {
            magId: this.magId,
            page: 0,
            size: 15,
            sort: 'totalCount5,desc'
          },
          ReferenceMag
        ),
        // 引证期刊分布
        this.getChartData(
          'YZQK',
          {
            magId: this.magId,
            page: 0,
            size: 15,
            sort: 'totalCount5,desc'
          },
          CitationMag
        ),
        // 机构发文统计
        this.getJGFWTJTableData(),
        // 引用机构分布
        this.getYYJGTableData(),
        // 引证机构分布
        this.getYZJGTableData(),
        //目标用户概览
        this.getPushMagAuthorNumber(versionUUid),
        // 任务推送目标人数来源分析
        // this.getPushMagAuthorSourceType(),
        this.getPushMagAuthorSetCount(versionUUid),
        // 任务推送目标人数算法来源分析
        this.getChartData('MBYHSFLY', { historyId: this.currentTask.id, sendEmailUuid: versionUUid || this.currentTask.sendEmailUuid || '' }, GetPushMagAuthorSourceType),
        // this.getPushMagAuthorType(),
        // 任务推送论文栏目
        this.isIssue ? this.getColumnDetail(versionUUid) : '',
        // 目标用户机构分布
        this.getChartData('MBYHJG', { historyId: this.currentTask.id, sendEmailUuid:  versionUUid || this.currentTask.sendEmailUuid || '', page: 0, size: 20 }, GetPushMagAuthorOrg),
        // 目标用户期刊分布
        this.getChartData('MBYHQK', { historyId: this.currentTask.id, sendEmailUuid:  versionUUid || this.currentTask.sendEmailUuid || '', page: 0, size: 20 }, GetPushMagAuthorMag)
      ]).then(() => {
        this.isDisabled = false
        this.loading = false
      })
    },
    // 获取echarts数据
    getChartData(type, params, url) {
      return new Promise((resolve, reject) => {
        url(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            let _this = this
            this.$nextTick(() => {
              if (type === 'YYQK') {
                _this.chartYYQK(data)
              } else if (type === 'YZQK') {
                _this.chartYZQK(data)
              } else if (type === 'TSYHLY') {
                _this.handleTSYHLYInfo(data)
              } else if (type === 'MBYHJG') {
                _this.chartMBYHJG(data)
              } else if (type === 'MBYHQK') {
                _this.chartMBYHQK(data)
              } else if (type === 'MBYHSFLY') {
                _this.chartMBYHSFLY(data.sourceTypList)
              }
              resolve()
            })
          } else {
            reject()
          }
        })
      })
    },
    // 引用期刊分布
    chartYYQK(data) {
      if (!document.getElementById('chartYYQK')) {
        return
      }
      const myChart = this.$echarts.init(document.getElementById('chartYYQK'))
      const titleArr = []
      const threeYearsArr = []
      const fiveYearsArr = []
      const impactFactorArr = []
      if (data && data.content.length > 0) {
        data.content.map((item) => {
          titleArr.push(item.refMagName)
          threeYearsArr.push(item.totalCount3)
          fiveYearsArr.push(item.totalCount5)
          impactFactorArr.push(item.influence1)
        })
      }
      myChart.setOption(
        {
          color: ['#BBE0E3', '#0070C0', '#F7B8A9'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'vertical',
            left: 'center',
            bottom: 'bottom',
            data: ['近3年合计', '近5年合计', '影响因子']
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function(value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '25%',
            top: '9%'
          },
          yAxis: [
            {
              type: 'value',
              name: '引用次数'
            },
            {
              type: 'value',
              name: '影响因子'
            }
          ],
          series: [
            {
              name: '近3年合计',
              type: 'bar',
              data: threeYearsArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '近5年合计',
              type: 'bar',
              data: fiveYearsArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '影响因子',
              type: 'line',
              yAxisIndex: 1,
              lineStyle: {
                type: 'dashed'
              },
              symbol: 'star',
              symbolSize: 8,
              data: impactFactorArr,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 引证期刊分布
    chartYZQK(data) {
      if (!document.getElementById('chartYZQK')) {
        return
      }
      const myChart = this.$echarts.init(document.getElementById('chartYZQK'))
      const titleArr = []
      const threeYearsArr = []
      const fiveYearsArr = []
      const impactFactorArr = []
      if (data && data.content.length > 0) {
        data.content.map((item) => {
          titleArr.push(item.ctiMagName)
          threeYearsArr.push(item.totalCount3)
          fiveYearsArr.push(item.totalCount5)
          impactFactorArr.push(item.influence1)
        })
      }
      myChart.setOption(
        {
          color: ['#BBE0E3', '#0070C0', '#F7B8A9'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'vertical',
            left: 'center',
            bottom: 'bottom',
            data: ['近3年合计', '近5年合计', '影响因子']
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function(value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '25%',
            top: '9%'
          },
          yAxis: [
            {
              type: 'value',
              name: '引证次数'
            },
            {
              type: 'value',
              name: '影响因子'
            }
          ],
          series: [
            {
              name: '近3年合计',
              type: 'bar',
              data: threeYearsArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '近5年合计',
              type: 'bar',
              data: fiveYearsArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            },
            {
              name: '影响因子',
              type: 'line',
              yAxisIndex: 1,
              lineStyle: {
                type: 'dashed'
              },
              symbol: 'star',
              symbolSize: 8,
              data: impactFactorArr,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 机构发文统计
    getJGFWTJTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        type: 'mag',
        sort: 'totalCount5,desc',
        top: 100
      }
      return new Promise((resolve, reject) => {
        Institution(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.JGFWTJTableData = content
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    // 引用机构分布
    getYYJGTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: 'totalCount5,desc',
        top: 100
      }
      return new Promise((resolve, reject) => {
        ReferenceInstitution(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.YYJGTableData = content
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    // 引证机构分布
    getYZJGTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: 'totalCount5,desc',
        top: 100
      }
      return new Promise((resolve, reject) => {
        CitationInstitution(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [] } = data
            this.YZJGTableData = content
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    //目标用户来源分布
    getPushMagAuthorSetCount(versionUUid,pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        historyId: this.currentTask.id,
        page: page - 1,
        size,
        sendEmailUuid: versionUUid || this.currentTask.sendEmailUuid
      }
      return new Promise((resolve, reject) => {
        GetPushMagAuthorSetCount(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.MBYHLYNewTableData = data
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    getPushMagAuthorSourceType(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        historyId: this.currentTask.id,
        page: page - 1,
        size,
        sendEmailUuid: this.currentTask.sendEmailUuid
      }
      return new Promise((resolve, reject) => {
        GetPushMagAuthorSourceType(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            // const { data } = res.data
            // console.log(testData, 'testData')
            // let columnObj, countObj
            // countObj = {
            //   source: '人数'
            // }
            // data.map((item, index) => {
            //   columnObj = {
            //     title: item.sourceType,
            //     notSortable: true,
            //     prop: 'count' + index
            //   }
            //   countObj['count' + index] = item.count
            //   this.MBYHLYNewColumnData.push(columnObj)
            // })
            // this.MBYHLYNewTableData.push(countObj)
            // this.MBYHLYNewTableData = data
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    //目标用户算法来源分布
    chartMBYHSFLY(data) {
      if (!document.getElementById('chartMBYHSFLY')) {
        return
      }
      const myChart = this.$echarts.init(document.getElementById('chartMBYHSFLY'))
      const titleArr = []
      const countArr = []
      if (data && data.length > 0) {
        data.map((item) => {
          titleArr.push(item.sourceType)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          color: ['#0070C0'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function(value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '15%',
            top: '10%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '人数'
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    getPushMagAuthorType(pageParams = {}) {
      // const { page = 1, size = 10 } = pageParams
      const params = {
        historyId: this.currentTask.id,
        // page: page - 1,
        // size,
        sendEmailUuid: this.currentTask.sendEmailUuid
      }
      return new Promise((resolve, reject) => {
        GetPushMagAuthorType(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            // this.chartMBYHSFLY
            // let columnObj, countObj
            // countObj = {
            //   source: '人数'
            // }
            // data.map((item, index) => {
            //   columnObj = {
            //     title: item.sourceType,
            //     notSortable: true,
            //     prop: 'count' + index
            //   }
            //   countObj['count' + index] = item.count
            //   this.MBYHLYColumnData.push(columnObj)
            // })
            // this.MBYHLYTableData.push(countObj)
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    //任务推送论文栏目
    getColumn() {
      const params = {
        historyId: this.currentTask.id
      }
      return new Promise((resolve, reject) => {
        GetColumn(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            if (data.length > 0) {
              // data.map((item) => {
              //   this.getColumnDetail(item)
              // })
            }
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    getColumnDetail(versionUUid) {
      const params = {
        historyId: this.currentTask.id,
        sendEmailUuid: versionUUid || this.currentTask.sendEmailUuid
      }
      GetPushMagAuthorTypeByColumn(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.issueColumnList = data
          this.issueColumnList.map((item) => {
            let columnObj = [
              {
                title: '来源',
                notSortable: true,
                prop: 'source',
                width: '50px'
              }
            ]
            let countObj = {
              source: '人数'
            }
            item.typeList.map((it, index) => {
              let Obj = {
                title: it.sourceType,
                notSortable: true,
                prop: 'count' + index
              }
              columnObj.push(Obj)
              countObj['count' + index] = it.count
            })
            let tableArr = []
            tableArr[0] = countObj
            item.data = tableArr
            item.columnData = columnObj
          })
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 目标用户概览
    getPushMagAuthorNumber(versionUUid) {
      const params = {
        historyId: this.currentTask.id,
        sendEmailUuid: versionUUid || this.currentTask.sendEmailUuid || ''
      }
      return new Promise((resolve, reject) => {
        GetPushMagAuthorNumber(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.userDataObj = data
            resolve()
          } else {
            const { message } = res.data
            this.$message.error(message)
            reject()
          }
        })
      })
    },
    // 目标用户机构分析
    chartMBYHJG(data) {
      if (!document.getElementById('chartMBYHJG')) {
        return
      }
      const myChart = this.$echarts.init(document.getElementById('chartMBYHJG'))
      const titleArr = []
      const countArr = []
      if (data && data.length > 0) {
        data.map((item) => {
          titleArr.push(item.orgName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          color: ['#0070C0'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function(value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '13%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '人数'
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    // 目标用户期刊分析
    chartMBYHQK(data) {
      if (!document.getElementById('chartMBYHQK')) {
        return
      }
      const myChart = this.$echarts.init(document.getElementById('chartMBYHQK'))
      const titleArr = []
      const countArr = []
      if (data && data.length > 0) {
        data.map((item) => {
          titleArr.push(item.magName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          color: ['#0070C0'],
          backgroundColor: '#fff',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function(value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '14%',
            top: '8%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1,
              name: '人数'
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20,
              itemStyle: {
                normal: {
                  label: {
                    show: true, // 开启显示
                    position: 'top', // 在上方显示
                    textStyle: {
                      // 数值样式
                      color: 'black',
                      fontSize: 12
                    }
                  }
                }
              }
            }
          ]
        },
        true
      )
    },
    formatTimestamp(timestamp) {
      const date = new Date(parseInt(timestamp, 10))
      if (isNaN(date.getTime())) {
        return ' '
      }
      return date.toLocaleDateString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
    },
    changeVersion(val) {
      this.getData(val)
    },
    getSendVersion() {
      return new Promise((resolve, reject) => {
        getSendVersion({ taskId: this.currentTask.id }).then((res) => {
          let { data,status, message } = res.data
          if (status) {
            this.$message.error(message)
            reject()
          } else {
            this.sendVersionOptions = data.map(_ => ({value:_, date:this.formatTimestamp(_)}))
            resolve()
          }
        })
      })
    }
  }
}
</script>
<style lang="scss">
.scholarAnalysis {
  .el-loading-spinner {
    top: 2%;
  }
  .table-content {
    .el-table {
      margin-top: 20px !important;
      overflow-y: hidden !important;
      tr {
        height: 30px !important;
      }
      th,
      td {
        height: 30px !important;
        padding: 0 !important;
        .cell {
          height: 30px !important;
          line-height: 30px !important;
        }
      }
      .el-table__header-wrapper th .cell {
        color: #fff !important;
      }
      th {
        background-color: #4054af !important;
      }
    }
  }
  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #d8ddf6 !important;
  }
}
</style>
<style lang="scss" scoped>
.scholarAnalysis {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .download-btn {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #4054af;
    display: flex;
    line-height: 27px;
    justify-content: flex-end;
    margin: 30px;
    position: relative;
    bottom: -440px;
    z-index: 99;
    img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
  }
  .download-btn-disabled {
    color: #c0c4cc;
    cursor: no-drop;
  }
  .report-content {
    width: 1040px;
    margin: auto;
    .page-part {
      position: relative;
      border-bottom: 0;
      padding: 30px;
      height: 525px;

      .table-content {
        display: flex;
        align-items: center;
        .table-content-box {
          margin: auto;
          width: 100%;
        }
      }
      .position-bottom {
        padding-top: 20px;
        .echarts-line {
          display: flex;
          align-items: center;
          justify-content: center;
          .line1 {
            width: 50%;
            height: 2px;
            background: #c6c3c3;
          }
          .line2 {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin: 0 8px;
            background: #c6c3c3;
          }
        }
        .bottom-info {
          display: flex;
          font-size: 12px;
          color: #929090;
          margin-top: 20px;
          line-height: 16px;
          text-align: left;
          .bottom-info-left {
            width: 50%;
            text-align: left;
          }
          .bottom-info-right {
            width: 50%;
            text-align: justify;
          }
        }
      }
      .echarts-first-title {
        display: flex;
        justify-content: space-between;
        color: #4054af;
        font-size: 18px;
        font-weight: bold;
        text-align: left;
      }
      .echarts-second-title {
        text-align: center;
      }
      .echarts-left-title {
        margin-top: 20px;
        text-align: left;
        font-size: 16px;
        span {
          color: #4054af;
        }
      }
      .version-wrapper {
        font-size: 14px;
      }
    }
    .flex-content {
      display: flex;
    }
    .page1 {
      align-items: center;
      justify-content: center;
      .title {
        font-size: 24px;
        font-weight: 600;
        color: #4054af;
        letter-spacing: 6px;
        position: relative;
        top: 200px;
      }
    }
    .page7 {
      .situation {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 97%;
        .situation-info {
          width: 45%;
          text-align: center;
          .title {
            font-size: 16px;
            font-weight: bold;
          }
          .info {
            margin-top: 6%;
            .text-box {
              width: 34%;
              display: inline-block;
              border: 1px solid rgb(47, 133, 204);
              font-size: 14px;
              border-radius: 10px;
              padding: 6% 10px;
              span {
                display: block;
                color: #4054af;
                font-size: 20px;
                margin-top: 6px;
              }
            }
            .ml-percent16 {
              margin-left: 12%;
            }
            .mt-percent16 {
              margin-top: 12%;
            }
          }
        }
      }
    }
  }
}
</style>
