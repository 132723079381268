<!-- magCover -->
<template>
  <div class="magCover-box">
    <el-form :model="ruleForm" :rules="rules">
      <el-form-item label="期刊介绍封面：" prop="magCoverId" style="width: auto;">
        <div style="position: relative;">
          <div class="magCover">
            <img class="fl" :src="magCoverSrc" alt />
            <el-upload :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" class="upload-demo" :auto-upload="true" :on-success="handleCoverSuccess">
              <el-button class="upload-btn" size="small" type="primary">上传图片</el-button>
            </el-upload>
          </div>
          <div slot="tip" class="el-upload__tip">格式支持：jpg、png、jpeg<span class="betterSize ml-20">尺寸推荐：156*231</span></div>
          <el-button v-if="showResetBtn" @click="resetCoverImg" style="margin: 0px 20px 0 0">重置</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream } from '@/api/configManage'
export default {
  name: 'magCover',
  data() {
    return {
      UploadPictureStream: UploadPictureStream,
      GetPictureStream: GetPictureStream,
      morImg: require('@/assets/img/img_default.png'),
      magCover: ''
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showResetBtn: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  computed: {
    //期刊介绍封面
    magCoverSrc() {
      return this.magCover ? this.magCover : this.ruleForm.magCoverId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.magCoverId}` : this.morImg
    }
  },
  mounted() {},
  methods: {
    // 成功上传期刊介绍封面
    handleCoverSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.magCover = URL.createObjectURL(file.raw)
      this.$set(this.ruleForm, 'magCoverId', ID)
    },
    // 重置上传cover图
    resetCoverImg() {
      this.magCover = ''
      this.ruleForm.magCoverId = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.magCover {
  .zdy_img {
    min-height: 100px;
    border: 1px solid #ddd;
    background: #f4f4f4;
    overflow: hidden;
    position: relative;
    height: 231px;
    width: 156px;
    img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .el-upload__tip {
    float: left;
    padding-left: 20px;
    color: #999;
    font-size: 14px;
  }
  .upload-cover {
    .postion2 {
      height: 231px;
      width: 156px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}
</style>
