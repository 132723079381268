<!-- contentConfigure -->
<template>
  <div class="contentConfigure w center_all">
    <div class="contentConfigure-box">
      <ul class="crumbs clearfix">
        <li>邮件推送</li>
        <li>-></li>
        <li>邮件内容配置</li>
      </ul>
      <div class="configure-box">
        <div class="part">
          <div class="topic mb-20">
            <h3 class="mb-10">邮件主题</h3>
            <div class="topic-box">
              <div class="topic-box-item">
                主题1（推送标题）
                <el-input class="topic-input" v-model="currentTask.topic"></el-input>
              </div>
              <div class="topic-box-item" v-for="(item, index) in pushTopicArr" :key="index">
                主题{{ index + 2 }}
                <el-input class="topic-input" v-model="item.topic"></el-input>
              </div>
              <!-- <div class="topic-box-item" v-if="configInfo.openPublishPush">
                本期发文作者主题：
                <el-input type="textarea" :rows="2" v-model="publishPushTopic"> </el-input>
              </div>
              <div class="topic-box-item" v-if="configInfo.openReferencePush">
                本期引文作者主题：
                <el-input type="textarea" :rows="2" v-model="referencePushTopic"> </el-input>
              </div> -->
            </div>
          </div>
          <div>
            <el-tabs v-model="editableTabsValue" type="card" editable @edit="handleTabsEdit">
              <el-tab-pane v-for="(item, index) in historyTemplateList" :key="index" :name="String(item.id)">
                <template slot="label">
                  <el-checkbox v-model="item.isChoice" :true-label="1" :false-label="0" @change="isChoiceTemplateChange($event, item)"></el-checkbox>
                  <el-input style="width: 160px" v-model="item.name" v-if="item.edit" @blur="item.edit = false"></el-input>
                  <div class="name" v-else @dblclick="item.edit = true">
                    {{ item.name }}
                  </div>
                </template>
              </el-tab-pane>
            </el-tabs>
            <div class="module mb-20" v-if="templateList.length > 0 && historyTemplateList.length > 0">
              <h3 class="mb-10">选择邮件模板</h3>
              <el-select class="module-select" v-model="ruleForm.pushTempalteId" placeholder="请选择">
                <el-option v-for="item in templateList" :key="item.id" :label="item.name" :value="item.id" @click.native="templateChange(item.id)" />
              </el-select>
            </div>
          </div>
        </div>
        <div class="style" :class="'style' + type" v-if="ruleForm && historyTemplateList.length > 0">
          <h3 class="mb-10">邮件样式</h3>
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
            <component :is="currentTemplateComList[type]" :ruleForm="ruleForm" :rules="rules" :configInfo="configInfo"></component>
            <el-form-item label="语种：">
              <el-select v-model="ruleForm.language" placeholder="请选择">
                <el-option v-for="item in languageList" :key="item.key" :label="item.value" :value="item.key" />
              </el-select>
            </el-form-item>
            <el-form-item label="文章目录：" v-if="type !== 3 && (historyTemplateList.length > 0 ? editableTabsValue == historyTemplateList[0].id : true)">
              <el-button type="primary" class="mb-20" @click="priorityDialogVisible = true">设置论文链接优先级</el-button>
              <issueArticle :currentTask="currentTask"></issueArticle>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-button size="big" @click="submit">保存</el-button>
      <el-dialog :visible.sync="priorityDialogVisible" title="设置论文链接优先级">
        <el-radio-group v-model="radio" v-for="(item, index) in typeArr" :key="index">
          <el-radio :label="item.value">{{ item.label }}<span style="margin: 0 20px" v-if="index !== typeArr.length - 1">></span></el-radio>
        </el-radio-group>
        <div class="btn" style="margin-top: 20px">
          <el-button size="small" round icon="el-icon-back" @click="toLeft">左移</el-button>
          <el-button size="small" round icon="el-icon-right" @click="toRight">右移</el-button>
        </div>
        <p style="margin-top: 20px">说明：从左到右，优先级依次递减，论文链接优先级高低从左到右依次选取</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="priorityDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="prioritySubmit"> 确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { GetTaskInfo } from '@/api/publishResults'
import {
  GetEmailModuleList,
  GetTemplate,
  SaveTemplate,
  GetHistoryTemplateSetType,
  NewEditTaskInfo,
  AddHistoryTemplate,
  DeleteHistoryTemplate,
  SetHistoryTemplate,
  UpdateHistoryTemplateChoose,
  GetEmailConfig,
  updateLanguage,
  updateHtmlOrder
} from '@/api/emailPush'
import { GetPictureStream, UploadPictureStream } from '@/api/configManage'
import Editor from '@tinymce/tinymce-vue'
import issueArticle from './components/issueArticle.vue'
import type1Template from './components/templates/type1Template.vue'
import type2Template from './components/templates/type2Template.vue'
import type3Template from './components/templates/type3Template.vue'
import type4Template from './components/templates/type4Template.vue'
import type5Template from './components/templates/type5Template.vue'
import type6Template from './components/templates/type6Template.vue'
import type11Template from './components/templates/type11Template.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'contentConfigure',
  data() {
    return {
      GetPictureStream,
      UploadPictureStream,
      morImg: require('@/assets/img/img_default.png'),
      currentTask: {},
      templateList: [],
      infoActiveName: 'one',
      bannerImg: '',
      footerImg: '',
      magCover: '',
      type: 1,
      rules1: {
        bannerId: [
          {
            message: '请上传邮件banner',
            trigger: 'blur,change',
            required: true
          }
        ],
        textBefore: [
          {
            message: '请填写期刊介绍/论文导语/说明文字',
            trigger: 'blur',
            required: true
          }
        ],
        column: [{ message: '请填写中文标题', trigger: 'blur', required: true }]
      },
      rules2: {
        bannerId: [
          {
            message: '请上传邮件banner',
            trigger: 'blur,change',
            required: true
          }
        ],
        textBefore: [{ message: '请填写期刊介绍', trigger: 'blur', required: true }],
        magCoverId: [{ message: '请上传封面图', trigger: 'blur,change', required: true }]
      },
      rules5: {
        textBefore: [{ message: '请填写期刊介绍', trigger: 'blur', required: true }]
      },
      pushTopicArr: [],
      editableTabsValue: null,
      historyTemplateList: [],
      ruleForm: {
        topic: '',
        articleIndexStart: '',
        bannerId: '',
        column: '',
        footerId: '',
        isChoice: 0,
        magCoverId: '',
        pushTempalteId: '',
        specialContent: '',
        specialTopic: '',
        textAfter: '',
        textBefore: '',
        textBeforeTitle: '',
        bannerLogoId: '',
        logoName: '',
        logoNameEn: '',
        bannerQrCodeId: '',
        publishPushContent: '',
        referencePushContent: '',
        language: 1
      },
      currentTemplateComList: {
        1: type1Template,
        2: type2Template,
        3: type3Template,
        4: type4Template,
        5: type5Template,
        6: type6Template,
        11: type11Template
      },
      publishPushTopic: '',
      referencePushTopic: '',
      configInfo: {},
      priorityDialogVisible: false,
      typeArr: [
        {
          label: '全文',
          value: 'htmlUrl'
        },
        {
          label: '知网',
          value: 'url'
        },
        {
          label: '摘要',
          value: 'abstractUrl'
        }
      ],
      radio: '',
      chooseIndex: null,
      htmlOrder: '',
      languageList: [
        {
          key: 1,
          value: '中文'
        },
        {
          key: 2,
          value: '英文'
        }
      ]
    }
  },
  props: {},
  components: { Editor, issueArticle },
  computed: {
    ...mapGetters({
      magId: 'magId'
    }),
    rules() {
      if (this.type === 1) {
        return this.rules1
      } else if (this.type === 2) {
        return this.rules2
      } else if (this.type === 4 || this.type === 5 || this.type === 6) {
        return this.rules5
      } else {
        return {}
      }
    },
    showLeft() {
      return (this.chooseIndex || this.chooseIndex == 0) && this.chooseIndex !== 0
    },
    showRight() {
      return (this.chooseIndex || this.chooseIndex == 0) && this.chooseIndex !== this.typeArr.length - 1
    }
  },
  watch: {
    editableTabsValue(n) {
      if (n && n != 0) {
        this.getSetType(n)
      }
      if (n && this.historyTemplateList.length > 0) {
        this.ruleForm = this.historyTemplateList.find((item) => {
          return item.id == this.editableTabsValue
        })
      }
    }
  },
  mounted() {
    this.getTaskInfoData()
    this.getTemplate()
    this.getConfig()
  },
  methods: {
    // 获取currentTask
    getTaskInfoData() {
      const params = {
        taskId: this.$route.query.taskId
      }
      GetTaskInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data) {
            this.currentTask = data
            this.pushTopicArr.length = 5
            this.pushTopicArr = [{ topic: '' }, { topic: '' }, { topic: '' }, { topic: '' }, { topic: '' }]
            if (this.currentTask.pushTopic) {
              let arr = this.currentTask.pushTopic.split(';')
              arr.map((item, index) => {
                this.pushTopicArr[index].topic = item
              })
            }
            this.ruleForm.topic = this.currentTask.topic
            this.ruleForm.language = this.currentTask.language || 1
            this.publishPushTopic = this.currentTask.publishPushTopic
            this.referencePushTopic = this.currentTask.referencePushTopic
            this.htmlOrder = this.currentTask.htmlOrder
            if (this.htmlOrder) {
              let arr = this.htmlOrder.split(',')
              this.typeArr = [
                {
                  label: arr[0] === 'htmlUrl' ? '全文' : arr[0] === 'url' ? '知网' : arr[0] === 'abstractUrl' ? '摘要' : '',
                  value: arr[0]
                },
                {
                  label: arr[1] === 'htmlUrl' ? '全文' : arr[1] === 'url' ? '知网' : arr[1] === 'abstractUrl' ? '摘要' : '',
                  value: arr[1]
                },
                {
                  label: arr[2] === 'htmlUrl' ? '全文' : arr[2] === 'url' ? '知网' : arr[2] === 'abstractUrl' ? '摘要' : '',
                  value: arr[2]
                }
              ]
            }
          }
          this.getModuleList()
        }
      })
    },
    // 获取模板列表
    getModuleList() {
      let params = {
        magId: this.currentTask.magId,
        page: 0,
        size: 999
      }
      GetEmailModuleList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data
          if (data) {
            this.templateList = data.content
          }
        }
      })
    },
    // 获取推送任务模板类型
    getSetType(id) {
      if (id) {
        let params = {
          id: id
        }
        GetHistoryTemplateSetType(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            let { data } = res.data
            this.type = data
          }
        })
      }
    },
    // 获取推送任务模板默认配置
    getTemplate(type) {
      let params = {
        historyId: this.$route.query.taskId
      }
      GetTemplate(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data
          this.historyTemplateList = data
          if (this.historyTemplateList.length > 0) {
            this.historyTemplateList.map((item) => {
              this.$set(item, 'edit', false)
            })
            if (type !== 'save') {
              this.editableTabsValue = String(this.historyTemplateList[0].id)
              this.ruleForm = this.historyTemplateList.find((item) => {
                return item.id == this.editableTabsValue
              })
            }
          }
          if (!type) {
            // this.getSetType()
          } else {
            this.$refs.ruleForm.clearValidate()
          }
        }
      })
    },
    // 是否选中模板改变
    isChoiceTemplateChange(val, item) {
      let params = {
        id: item.id,
        isChoice: val
      }
      UpdateHistoryTemplateChoose(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
        }
      })
    },
    // 邮件模板改变
    templateChange(val) {
      this.ruleForm.pushTempalteId = val
      this.saveHistoryTemplate()
    },
    // 改变邮件模板
    saveHistoryTemplate() {
      if (this.ruleForm.id && this.ruleForm.pushTempalteId) {
        let params = {
          id: this.ruleForm.id,
          pushTemplateId: this.ruleForm.pushTempalteId
        }
        SetHistoryTemplate(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.ruleForm = res.data.data
            this.getSetType(this.ruleForm.id)
          }
        })
      }
    },
    // 提交
    submit() {
      if (this.ruleForm.isChoice && this.historyTemplateList.length > 0) {
        if (!this.ruleForm.pushTempalteId) {
          this.$message.error('请选择模板')
          return
        }
        if (this.type === 1) {
          if (!this.ruleForm.bannerId || !this.ruleForm.textBefore || !this.ruleForm.column) {
            this.$message.error('请填写必填项！')
            return
          }
        }
        if (this.type === 2) {
          if (!this.ruleForm.bannerId || !this.ruleForm.textBefore || !this.ruleForm.magCoverId) {
            this.$message.error('请填写必填项！')
            return
          }
        }
        if (this.type === 3) {
          if (!this.ruleForm.bannerId && !this.ruleForm.textBefore && !this.ruleForm.textAfter && !this.ruleForm.footerId) {
            this.$message.error('请填写任意一项内容！')
            return
          }
        }
        if (this.type === 4 || this.type === 5 || this.type === 6) {
          if (!this.ruleForm.textBefore) {
            this.$message.error('请填写必填项！')
            return
          }
        }
        this.saveSetting()
        this.saveTopic()
      } else {
        this.saveTopic()
      }
    },
    // 保存邮件配置
    saveSetting() {
      SaveTemplate({ ...this.ruleForm }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '设置成功'
          })
          this.getTemplate('save')
        }
      })
    },
    // 保存邮件标题
    saveTopic() {
      let pushTopic = ''
      this.pushTopicArr.map((item) => {
        if (item.topic) {
          pushTopic = pushTopic + item.topic + ';'
        }
      })
      console.log(this.configInfo, 'this.configInfo')
      let params = {
        magId: this.currentTask.magId,
        topic: this.currentTask.topic,
        taskId: this.$route.query.taskId,
        operatorName: '方正运营',
        pushTopic: pushTopic
        // htmlOrder: this.htmlOrder,
        // language: this.ruleForm.language
      }
      if (this.configInfo.openReferencePush) {
        params.referencePushTopic = this.referencePushTopic
      }
      if (this.configInfo.openPublishPush) {
        params.publishPushTopic = this.publishPushTopic
      }
      NewEditTaskInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '保存成功'
          })
          this.updateLanguage()
          this.updateHtmlOrder()
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    updateLanguage() {
      let params = {
        taskId: this.$route.query.taskId,
        language: this.ruleForm.language
      }
      updateLanguage(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    updateHtmlOrder() {
      this.htmlOrder = ''
      this.typeArr.map((item, index) => {
        this.htmlOrder = index === this.typeArr.length - 1 ? this.htmlOrder + item.value : this.htmlOrder + item.value + ','
      })
      let params = {
        taskId: this.$route.query.taskId,
        htmlOrder: this.htmlOrder
      }
      updateHtmlOrder(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 获取当前配置
    getConfig() {
      let params = {
        magId: this.magId
      }
      GetEmailConfig(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          this.configInfo = { ...data }
        }
      })
    },
    // 增加/删除tab键
    handleTabsEdit(targetName, action) {
      if (action === 'add') {
        let params = {
          historyId: this.$route.query.taskId
        }
        AddHistoryTemplate(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            this.$message({
              type: 'success',
              message: '添加成功'
            })
            this.getTemplate()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      }
      if (action === 'remove' && !this.ruleForm.edit) {
        this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            DeleteHistoryTemplate(params).then((res) => {
              if (res && res.data && res.data.status === 0) {
                this.$message({
                  type: 'success',
                  message: '删除成功'
                })
                this.getTemplate()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
        let params = {
          id: targetName
        }
      }
    },
    // 文章优先级左移
    toLeft() {
      this.typeArr.map((item, index) => {
        if (item.value === this.radio) {
          this.chooseIndex = index
        }
      })
      if (this.chooseIndex === 0) {
        this.$message.error('已经在第一位，不可左移')
        return
      } else {
        let newArr = this.swapItems(this.typeArr, this.chooseIndex, this.chooseIndex - 1)
        this.typeArr = newArr
        this.computedShowLeftRight()
      }
      console.log(this.chooseIndex, 'this.chooseIndex')
    },
    // 文章优先级右移
    toRight() {
      this.typeArr.map((item, index) => {
        if (item.value === this.radio) {
          this.chooseIndex = index
        }
      })
      if (this.chooseIndex === this.typeArr.length - 1) {
        this.$message.error('已经在最后一位，不可右移')
        return
      } else {
        let newArr = this.swapItems(this.typeArr, this.chooseIndex, this.chooseIndex + 1)
        this.typeArr = newArr
        this.computedShowLeftRight()
      }
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    },
    computedShowLeftRight() {
      this.typeArr.map((item, index) => {
        if (item.value === this.radio) {
          this.chooseIndex = index
        }
      })
      this.htmlOrder = ''
      this.typeArr.map((item, index) => {
        this.htmlOrder = index === this.typeArr.length - 1 ? this.htmlOrder + item.value : this.htmlOrder + item.value + ','
      })
    },
    prioritySubmit() {
      this.priorityDialogVisible = false
      this.htmlOrder = ''
      this.typeArr.map((item, index) => {
        this.htmlOrder = index === this.typeArr.length - 1 ? this.htmlOrder + item.value : this.htmlOrder + item.value + ','
      })
    }
  }
}
</script>
<style lang="scss">
.contentConfigure {
  .style {
    .el-form-item {
      width: 812px;
    }
    .el-form-item__label {
      display: contents;
    }
    .min-height-textarea {
      .el-textarea__inner {
        min-height: 400px !important;
      }
    }
    .tox .tox-toolbar__primary {
      width: 100%;
    }
  }
  .style2 {
    .min-height-textarea {
      .el-textarea__inner {
        min-height: 650px !important;
      }
    }
    .info-box {
      .info-left {
        .el-form-item {
          width: 100%;
        }
      }
      .info-right {
        .el-form-item {
          width: auto;
        }
      }
    }
  }
  .el-tabs--card > .el-tabs__header {
    margin-top: 0 !important;
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item {
    font-size: 16px;
    display: flex;
    align-items: center;
    .el-checkbox {
      margin-right: 6px;
    }
  }
  .el-tabs__nav {
    display: flex;
  }
  .el-tabs__new-tab {
    border-color: #265cca;
    color: #265cca;
    .el-icon-plus {
      font-weight: 800;
    }
  }
}
</style>
<style lang="scss">
.contentConfigure {
  .contentConfigure-box {
    padding: 0 30px 30px;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .configure-box {
    text-align: left;
    .part {
      .topic {
        .topic-box {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .topic-box-item {
            width: 49%;
            margin: 10px 0;
          }
        }
        .topic-input {
          width: 70%;
        }
      }
      .module {
        .module-select {
          width: 40%;
        }
      }
    }
    .style {
      .upload-img {
        width: 812px;
        min-height: 100px;
        border: 1px solid #ddd;
        background: #f4f4f4;
        overflow: hidden;
        position: relative;
        img {
          height: auto;
          max-height: 100%;
          max-width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .upload-demo {
          text-align: left;
          line-height: 0;
          .upload-btn {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
      }
      .magCover {
        height: 231px;
        width: 156px;
        border: 1px solid #ddd;
        background: #f4f4f4;
        overflow: hidden;
        position: relative;
        img {
          height: auto;
          max-height: 100%;
          max-width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .upload-btn {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }
    .style1,
    .style4,
    .style5,
    .style6 {
      .info-box {
        display: flex;
        width: 100%;
      }
    }
    .style2 {
      .info-box {
        display: flex;
        width: 100%;
        .info-left {
          width: 492px;
        }
        .info-right {
          width: 300px;
        }
      }
    }
  }
}
</style>
