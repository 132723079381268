<template>
  <div class="push-dialog">
    <el-dialog :visible.sync="dialogPushVisible" width="400px" :show-close="true" class="pushVisible" title="提示">
      <!-- <div class="reflesh-author-box">
        <div>上次刷新学者时间：{{ refleshAuthorTime }}</div>
        :class="refleshDisabled ? 'reflesh-disabled' : ''"
        <div class="reflesh-author" @click="preparePushMagHistoryAuthor">刷新学者</div>
      </div> -->
      <p class="pushDialog">
        您即将对{{ currentTask ? currentTask.topic : '' }}内容进行正式推送
        <!-- ,共推送用户{{ userNumberText }}个,是否现在推送? -->
        <!-- <button type="text" @click="handleDownloadPushUser" :class="!userNumber || refleshDisabled ? 'txtDisabled' : ''">
          下载推送学者
        </button> -->
      </p>
      <div class="code-info">
        <el-form ref="codeForm" :model="codeForm" :rules="codeFormRules" status-icon label-width="80px">
          <el-form-item label="手机号：" prop="receiver" size="zdy">
            <el-input v-model="codeForm.receiver" />
          </el-form-item>
          <el-form-item label="验证码：" size="zdy" prop="code" class="code">
            <el-input v-model="codeForm.code" />
            <el-button type="text" :disabled="codeDisabled" @click="getCode">{{ codeText }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPushVisible = false">取 消</el-button>
        <el-button type="primary" @click="fanalPushBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { TaskSend, SendReceiverMessage } from '@/api/emailPush'
export default {
  data() {
    var validateReceiver = (rule, value, callback) => {
      var reg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      pageObj: {},
      dialogPushVisible: false,
      // userNumber: 0,
      currentTask: null,
      currentType: null, //空 默认为扩展推送（邮件推送也为扩展推送）1 扩展推送 是会做备份 4 激活学者推送
      codeForm: {
        receiver: '',
        code: ''
      },
      codeFormRules: {
        receiver: [
          {
            validator: validateReceiver,
            trigger: 'blur',
            required: true
          }
        ],
        code: [{ message: '请填写验证码', trigger: 'blur', required: true }]
      },
      codeText: '获取验证码',
      codeDisabled: true,
      refleshDisabled: false
    }
  },
  watch: {
    'codeForm.receiver'(value) {
      var reg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      this.codeDisabled = !reg.test(value)
    }
  },
  computed: {
    // refleshAuthorText() {
    //   return this.refleshDisabled ? '正在分析' : '刷新学者'
    // },
    // userNumberText() {
    //   return this.refleshDisabled ? '--' : this.userNumber ? this.userNumber : 0
    // },
    // refleshAuthorTime() {
    //   return this.currentTask ? TimestampToTime(this.currentTask.sendEmailUuid) : ''
    // }
  },
  mounted() {},
  methods: {
    initData() {
      // this.userNumber = 0
      this.$nextTick(() => {
        this.$refs['codeForm'].resetFields()
      })
    },
    // 获取推送学者人数
    getSendMemberSize(item, type, pageObj) {
      this.initData()
      this.currentTask = item
      this.currentType = type
      this.pageObj = pageObj
      // let params = {
      //   id: item.id
      // }
      // GetSendMemberSize(params).then((res) => {
      //   if (res && res.data && res.data.status === 0) {
      //     const { data } = res.data
      //     this.userNumber = data
      //   }
      // })
      // this.CheckPushMagHistoryAuthorTime()
    },
    // //下载推送学者
    // handleDownloadPushUser() {
    //   if (!this.userNumber || this.refleshDisabled) {
    //     return
    //   }
    //   if (this.userNumber) {
    //     window.open(`${exportSendMember}?taskId=${this.currentTask.id}&type=${this.currentType}`, '_self')
    //   }
    // },
    // 获取验证码
    getCode() {
      this.countdown(60)
      let params = {
        receiver: this.codeForm.receiver
      }
      SendReceiverMessage(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '发送成功!'
          })
        }
      })
    },
    // 倒计时
    countdown(time) {
      if (time === 0) {
        this.codeText = '获取验证码'
        this.codeDisabled = false
      } else {
        this.codeText = `${time}秒后重新获取`
        this.codeDisabled = true
        time--
        setTimeout(() => {
          this.countdown(time)
        }, 1000)
      }
    },
    // 最终推送确定
    fanalPushBtn() {
      this.$refs['codeForm'].validate((valid) => {
        if (valid) {
          let params = {
            taskId: this.currentTask.id,
            nameSpace: window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/das-api',
            operatorName: '方正运营',
            type: this.currentType,
            receiver: this.codeForm.receiver,
            code: this.codeForm.code
          }
          TaskSend(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '推送成功!'
              })
              this.dialogPushVisible = false
              this.$emit('pushSuccess', this.pageObj, this.currentTask)
            } else {
              const { message = '' } = res.data
              this.$message.error(message)
            }
          })
        }
      })
    }
    // // 推送任务学者分析检查是否需要初始化
    // CheckPushMagHistoryAuthorTime() {
    //   let params = {
    //     id: this.currentTask.id
    //   }
    //   CheckPushMagHistoryAuthorTime(params).then((res) => {
    //     if (res && res.data && res.data.status === 0) {
    //       // 0 表示需要初始化  1 表示正在初始化 2表示初始化完成
    //       const { data } = res.data
    //       if (data == 1) {
    //         this.refleshDisabled = true
    //       } else {
    //         this.refleshDisabled = false
    //       }
    //     } else {
    //       const { message = '' } = res.data
    //       this.$message.error(message)
    //     }
    //   })
    // },
    // // 刷新学者
    // preparePushMagHistoryAuthor() {
    //   if (this.refleshDisabled) {
    //     this.$message({
    //       type: 'info',
    //       message: '正在分析，请过几分钟后查看'
    //     })
    //   } else {
    //     let params = {
    //       id: this.currentTask.id,
    //       isReset: true
    //     }
    //     PreparePushMagHistoryAuthor(params).then((res) => {
    //       if (res && res.data && res.data.status === 0) {
    //         this.$message({
    //           type: 'success',
    //           message: '开始初始化学者!'
    //         })
    //         this.refleshDisabled = true
    //         // 获取选中的task的详情数据
    //         const params = {
    //           taskId: this.currentTask.id
    //         }
    //         GetTaskInfo(params).then((res) => {
    //           if (res && res.data && res.data.status === 0) {
    //             const { data } = res.data
    //             this.currentTask = data
    //             sessionStorage.setItem('emailCurrentTask', JSON.stringify(data))
    //           } else {
    //             const { message = '' } = res.data
    //             this.$message.error(message)
    //           }
    //         })
    //       }
    //     })
    //   }
    // }
  }
}
</script>
<style lang="scss">
.push-dialog {
  .code {
    .el-form-item__content {
      display: flex;
      .el-button--text {
        top: 0;
        margin-left: 10px;
        text-indent: 0;
      }
    }
  }
}
.el-message {
  z-index: 3040;
}
</style>
<style lang="scss" scoped>
.push-dialog {
  // .reflesh-author-box {
  //   display: flex;
  //   justify-content: space-between;
  //   margin-bottom: 10px;
  //   .reflesh-author {
  //     text-align: right;
  //     color: #265cca;
  //     cursor: pointer;
  //   }
  // }
  // 推送弹框
  .pushVisible {
    background: rgba(0, 0, 0, 0.3) !important;
    .pushDialog button {
      color: #265cca;
      cursor: pointer;
      border: none;
      background: none;
      outline: none;
    }
    .txtDisabled {
      cursor: no-drop !important;
      color: #91949d !important;
    }
  }
}
.code-info {
  margin-top: 30px;
}
.reflesh-disabled {
  color: #999 !important;
  cursor: no-drop !important;
}
</style>
