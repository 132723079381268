<!-- issueArticle -->
<template>
  <div class="issueArticle">
    <div class="btn-box mb-20">
      <el-button class="article-demo mr-20" type="primary" :disabled="!haveArticle" @click="chooseOfficialWebsiteArticle">官网文章</el-button>
      <el-upload
        v-if="currentTask.type == 2"
        ref="uploadExcel"
        name="file"
        :auto-upload="true"
        :before-upload="beforeUploadFile"
        :on-success="handleSuccess"
        :on-error="handleError"
        :data="articleForm"
        class="upload-btn mr-20"
        :action="articleUploadUrl"
        accept=".zip"
      >
        <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading">浏览</el-button>
      </el-upload>
      <div class="shili">
        <a href="#" @click="handleDownloadSample">样例文件</a>
      </div>
    </div>
    <!-- 新建每期文章按钮 -->
    <el-button class="new-btn fl mb-20" size="small" round icon="el-icon-plus" @click="newArticle">新增</el-button>
    <el-button class="new-btn fl mb-20" v-if="currentTask.type === 4" size="small" round @click="computedMoreArticles">计算“更多推荐”文章</el-button>
    <el-button class="new-btn fl mb-20" v-if="currentTask.type === 4" size="small" round @click="chooseOfficialWebsiteArticle(1)">选择推荐文章</el-button>
    <!-- 编辑微网刊每期文章按钮 -->
    <el-button class="new-btn fl" v-if="chooseList.length === 1" size="small" round icon="el-icon-edit" @click="editArticle">编辑</el-button>
    <!-- 新建编辑文章弹框 -->
    <el-dialog :show-close="true" :visible.sync="newArticleDialogVisible" width="800px" :title="newArticleTitle">
      <el-form ref="newArticleForm" :model="newArticleForm" :rules="newArticleRules" status-icon label-width="120px" class="new-article-form">
        <el-form-item label="推送文章标题：" size="zdy" prop="title">
          <el-input id="title" v-model="newArticleForm.title" />
        </el-form-item>
        <el-form-item label="英文标题：" size="zdy" prop="titleEn" v-if="issueNewEditStatus === 'new'">
          <el-input v-model="newArticleForm.titleEn" />
        </el-form-item>
        <el-form-item label="摘要：" size="zdy" prop="abstractContent">
          <el-input type="textarea" autosize v-model="newArticleForm.abstractContent" />
        </el-form-item>
        <el-form-item label="栏目：" size="zdy" prop="columnBelong">
          <el-input v-model="newArticleForm.columnBelong" />
        </el-form-item>
        <el-form-item label="机构：" size="zdy" prop="institutions">
          <el-input type="textarea" autosize v-model="newArticleForm.institutions" />
        </el-form-item>
        <el-form-item label="作者：" size="zdy" prop="authors">
          <el-input v-model="newArticleForm.authors" />
        </el-form-item>
        <el-form-item label="刊期：" size="zdy" prop="pubYearStage" v-if="issueNewEditStatus === 'edit'">
          <el-input v-model="newArticleForm.pubYearStage" />
        </el-form-item>
        <el-form-item label="关键词：" size="zdy" prop="keywords">
          <el-input v-model="newArticleForm.keywords" />
        </el-form-item>
        <el-form-item label="全文URL：" size="zdy" prop="htmlUrl">
          <el-input type="textarea" autosize v-model="newArticleForm.htmlUrl" />
        </el-form-item>
        <el-form-item label="预览图：" size="zdy4" prop="coverId" v-if="issueNewEditStatus === 'new'">
          <div class="new-article-img-box">
            <div class="new-article-img">
              <img class="fl" :src="newArticleCoverSrc ? newArticleCoverSrc : morImg" alt />
            </div>
            <el-upload
              :action="UploadPictureStream"
              name="file"
              :show-file-list="false"
              accept=".png,.jpg,.jpeg"
              class="new-article-upload"
              :auto-upload="true"
              :on-success="handleNewArticleCoverSuccess"
            >
              <el-button size="small" type="primary">上传图片</el-button>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newArticleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="newArticleBtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 任务文章列表 -->
    <SimpleTable
      ref="issueTable"
      class="issueTable"
      maxHeight="840px"
      noPagination
      hasSelect
      showUpBottom
      showDelete
      showOperate
      showUpload
      isDisabledClick
      showOperateText="预览图"
      btn1Text="查看"
      :showBtn2="false"
      :uploadUrl="UploadPictureStream"
      :tableData="tableData"
      :columnData="resultArticleColumn"
      :disabledEvents="isPublishDisabled === 'is-publish-disabled'"
      @onClickBtn1="handlePreviewImg"
      @onHandleUploadSuccess="handleUploadSuccess"
      @onChangeToTop="handleToTop"
      @onChangeToBottom="handleToBottom"
      @onChangeDeleteItems="handleDeleteItems"
      @onChangeInputBlur="handleChangeInputBlur"
      @onClickTitle="handleResultOpenUrl"
      @onChangeSelection="handleChangeSelection"
    />
    <!-- 官网文章弹框 -->
    <officialWebsiteArticleDialog ref="officialWebsiteArticle" type="email" :taskId="currentTask.id" :taskInfo="currentTask" @SaveOfficialArticle="getTaskArticles"></officialWebsiteArticleDialog>
    <!-- 任务文章列表封面预览 -->
    <el-dialog :visible.sync="previewImgDialogVisible" width="30%">
      <div class="preview-img-dialog">
        <img :src="issueCoverSrc" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { DeleteIssueArticle, IssueUpOrBottom, UploadIssueCover, SaveIssueArticle } from '@/api/emailPush'
import { apiGetTaskArticles, UpdateEmailArticleList, SampleDownload, articleUploadUrl, getInitRecommendArticleStatus, initPushRecommendArticle } from '@/api/selfPush'
import officialWebsiteArticleDialog from '../../components/officialWebsiteArticleDialog'
import { GetIssuesByMagId } from '@/api/selfPush'
import { GetPictureStream, UploadPictureStream } from '@/api/configManage'
import SimpleTable from '@/components/SimpleTable'
export default {
  name: 'issueArticle',
  data() {
    return {
      UploadPictureStream,
      GetPictureStream,
      articleUploadUrl,
      haveArticle: false, //是否有官方文章
      tableData: [],
      resultArticleColumn: [
        {
          title: '推送文章标题',
          prop: 'thesisName',
          notSortable: true,
          edit: true,
          clickParam: 'skipLink',
          width: '100px'
        },
        {
          title: '栏目',
          prop: 'column',
          notSortable: true,
          edit: true
        },
        {
          title: '机构',
          prop: 'org',
          notSortable: true,
          edit: true
        },
        {
          title: '作者',
          prop: 'authors',
          notSortable: true,
          edit: true
        },
        {
          title: '刊期',
          prop: 'pubYearStage',
          notSortable: true,
          edit: true
        },
        {
          title: '关键词',
          prop: 'keywords',
          notSortable: true,
          edit: true
        },
        {
          title: 'URL',
          prop: 'skipLink',
          notSortable: true,
          edit: true
        }
      ],
      previewImgDialogVisible: false,
      issueCoverSrc: '',
      fullscreenLoading: false,
      articleForm: {
        taskId: '',
        operator: ''
      },
      newArticleDialogVisible: false,
      newArticleForm: {
        title: '',
        titleEn: '',
        abstractContent: '',
        columnBelong: '',
        institutions: '',
        authors: '',
        keywords: '',
        htmlUrl: '',
        coverId: '',
        pubYearStage: ''
      },
      editArticleForm: {
        thesisName: '',
        skipLink: '',
        org: '',
        authors: '',
        keywords: '',
        column: '',
        pubYearStage: '',
        abstractContent: ''
      },
      newArticleRules: {
        title: [{ message: '请填写推送文章标题', trigger: 'blur', required: true }],
        htmlUrl: [{ message: '请填写全文url', trigger: 'blur', required: true }]
      },
      newArticleCoverSrc: '',
      morImg: require('@/assets/img/img_default.png'),
      chooseList: [],
      issueNewEditStatus: '',
      recommendArticleStatus: ''
    }
  },
  props: {
    currentTask: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { officialWebsiteArticleDialog, SimpleTable },
  computed: {
    isPublishDisabled() {
      return this.currentTask && (this.currentTask.status === '已推送' || this.currentTask.status === '推送中') ? 'is-publish-disabled' : ''
    },
    newArticleTitle() {
      return this.issueNewEditStatus === 'new' ? '新增文章' : this.issueNewEditStatus === 'edit' ? '编辑文章' : ''
    }
  },
  watch: {
    currentTask() {
      if (JSON.stringify(this.currentTask) !== '{}') {
        this.getIssuesByMagId()
        this.getTaskArticles()
      }
    }
  },
  mounted() {
    if (JSON.stringify(this.currentTask) !== '{}') {
      this.getIssuesByMagId()
      this.getTaskArticles()
      if (this.currentTask.type === 4) {
        let params = {
          historyId: this.currentTask.id
        }
        getInitRecommendArticleStatus(params).then((res) => {
          this.recommendArticleStatus = res.data
        })
      }
    }
  },
  methods: {
    //新增微网刊每期文章
    newArticle() {
      this.newArticleDialogVisible = true
      this.issueNewEditStatus = 'new'
      this.newArticleCoverSrc = ''
      this.newArticleForm = {
        title: '',
        titleEn: '',
        abstractContent: '',
        columnBelong: '',
        institutions: '',
        authors: '',
        keywords: '',
        htmlUrl: '',
        coverId: ''
      }
      this.$nextTick(() => {
        this.$refs['newArticleForm'].resetFields()
      })
    },
    //新增微网刊每期文章封面图上传成功
    handleNewArticleCoverSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.newArticleCoverSrc = URL.createObjectURL(file.raw)
      this.newArticleForm.coverId = ID
    },
    //新增微网刊每期文章确定
    newArticleBtn() {
      if (this.issueNewEditStatus === 'new') {
        let params = { historyId: this.currentTask.id, ...this.newArticleForm }
        this.$refs['newArticleForm'].validate((valid) => {
          if (!valid) {
            document.getElementById('topic').focus()
          } else {
            SaveIssueArticle(params).then((res) => {
              if (res && res.data && res.data.status === 0) {
                this.$message({
                  message: '新建文章成功！',
                  duration: 1500,
                  type: 'success'
                })
                this.newArticleDialogVisible = false
                this.getTaskArticles()
              } else {
                const { message = '' } = res.data
                this.$message.error(message)
              }
            })
          }
        })
      }
      if (this.issueNewEditStatus === 'edit') {
        this.$refs['newArticleForm'].validate((valid) => {
          if (!valid) {
            document.getElementById('topic').focus()
          } else {
            this.editArticleForm = {
              thesisName: this.newArticleForm.title,
              skipLink: this.newArticleForm.htmlUrl,
              org: this.newArticleForm.institutions,
              authors: this.newArticleForm.authors,
              keywords: this.newArticleForm.keywords,
              column: this.newArticleForm.columnBelong,
              pubYearStage: this.newArticleForm.pubYearStage,
              abstractContent: this.newArticleForm.abstractContent
            }
            let params = { id: this.chooseList[0].id, ...this.editArticleForm }
            UpdateEmailArticleList(params).then((resp) => {
              let { data, status } = resp.data
              if (status !== 0) {
                this.$message({
                  type: 'error',
                  message: data.message
                })
              } else {
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                })
                this.newArticleDialogVisible = false
                this.getTaskArticles()
                this.$nextTick(() => {
                  this.$refs.issueTable.$refs.simpleTable.clearSelection()
                })
              }
            })
          }
        })
      }
    },
    // 编辑微网刊每期文章
    editArticle() {
      this.newArticleDialogVisible = true
      let chooseItem = this.chooseList[0]
      this.issueNewEditStatus = 'edit'
      this.editArticleForm
      for (var item in this.newArticleForm) {
        if (chooseItem.hasOwnProperty(item)) {
          this.newArticleForm[item] = chooseItem[item]
        }
      }
      this.newArticleForm.title = chooseItem.thesisName
      this.newArticleForm.columnBelong = chooseItem.column
      this.newArticleForm.institutions = chooseItem.org
      this.newArticleForm.pubYearStage = chooseItem.pubYearStage
      this.newArticleForm.htmlUrl = chooseItem.skipLink
      this.newArticleCoverSrc = `${this.GetPictureStream}?pictureId=${chooseItem.recodeId}`
    },
    handleChangeSelection(item, chooseList) {
      this.chooseList = chooseList
    },
    //获取官网文章数据
    getIssuesByMagId() {
      let params = {
        magId: this.currentTask.magId,
        size: 999
      }
      GetIssuesByMagId(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.haveArticle = data.totalElements > 0
        }
      })
    },
    //点击官网文章
    chooseOfficialWebsiteArticle(articleType) {
      this.$nextTick(() => {
        this.$refs.officialWebsiteArticle.showOfficialWebsiteArticle = true
        if (articleType == 1)this.$refs.officialWebsiteArticle.articleType = 1
      })
    },
    // 获取当前任务文章列表
    getTaskArticles(type, item) {
      let params = {
        taskId: this.currentTask.id,
        type: this.currentTask.type === 4 ? '' : 0
      }
      apiGetTaskArticles(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.tableData = res.data.data
          this.isShow = this.tableData.length > 0 ? true : false
          if (this.tableData.length > 0) {
            this.tableData.map((item, index) => {
              item.activeBtn1 = typeof item.recodeId === 'number'
              item.issueCoverSrc = `${this.GetPictureStream}?pictureId=${item.recodeId}`
            })
          }
          if (type === 'move') {
            this.$nextTick(() => {
              this.$refs.issueTable.initData()
              this.$refs.issueTable.cumputedChooseCurrentIndex(item)
            })
          } else if (type === 'delete') {
            this.$nextTick(() => {
              this.$refs.issueTable.initData()
            })
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    //微网刊每期文章列表数据图片预览
    handlePreviewImg(item) {
      this.previewImgDialogVisible = true
      this.issueCoverSrc = item.issueCoverSrc
    },
    //微网刊每期文章列表数据图片上传成功后
    handleUploadSuccess(id, url, item) {
      const params = {
        coverId: id,
        pushArticleId: item.id
      }
      UploadIssueCover(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.getTaskArticles()
          this.$message({
            type: 'success',
            message: '上传成功'
          })
        }
      })
    },
    // 文章列表数据上移
    handleToTop(index, chooseId, item) {
      this.articleUpOrBottom(item.id, 'up', item)
    },
    // 文章列表数据下移
    handleToBottom(index, chooseId, item) {
      this.articleUpOrBottom(item.id, 'down', item)
    },
    // 文章列表数据上移下移接口
    articleUpOrBottom(id, type, item) {
      let params = {
        id: id,
        type: type === 'up' ? -1 : 1
      }
      IssueUpOrBottom(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '移动成功!'
          })
          this.getTaskArticles('move', item)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 文章列表数据删除
    handleDeleteItems(chooseId) {
      let params = {
        id: chooseId
      }
      DeleteIssueArticle(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getTaskArticles('delete')
          this.$nextTick(() => {
            this.$refs.issueTable.$refs.simpleTable.clearSelection()
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 文章列表跳转页面
    handleResultOpenUrl(chooseItem, columnItem) {
      const { clickParam = '' } = columnItem
      if (clickParam && clickParam !== '' && chooseItem[clickParam] !== '') {
        window.open(chooseItem[clickParam])
      }
    },
    // 处理文章列表为input框时失焦事件
    handleChangeInputBlur(val, prop, event, index, content) {
      let params = {
        id: this.tableData[index].id,
        [prop]: content
      }
      UpdateEmailArticleList(params).then((resp) => {
        let { data, status, message } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: message
          })
        } else {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.getTaskArticles()
        }
      })
    },
    handleDownloadSample() {
      // 下载样例文件
      window.open(SampleDownload, '_self')
    },
    //浏览上传附件开始
    // 上传文件之前的钩子, 参数为上传的文件,若返回false或者返回Promise且被reject,则停止上传
    beforeUploadFile(file) {
      let fileType = ''
      let arr = ['zip']
      let filePath = file.name
      let fileNameLength = file.name.lastIndexOf('.')
      if (fileNameLength != -1) {
        fileType = filePath.substring(fileNameLength + 1, filePath.length).toLowerCase()
      } else {
        fileType = ''
      }
      if (!arr.includes(fileType)) {
        this.$message({
          message: '请上传' + arr + '类型文件！',
          duration: 1500,
          type: 'error'
        })
        return false
      }
      // if (this.ruleForm.topic) {
      //   this.ruleForm.list = file.name
      this.fullscreenLoading = true // 等待loading 开启
      this.articleForm.taskId = this.currentTask.id
      //   this.articleForm.operator = sessionStorage.getItem('realName')
      // } else {
      //   this.$notify.warning({
      //     title: '警告',
      //     message: `请输入主题`
      //   })
      //   return false
      // }
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      this.$refs.uploadExcel.clearFiles() // 清除上次上传记录
      this.fullscreenLoading = false // 等待loading 关闭

      if (res && res.status === 0) {
      } else {
        const { message = '' } = res
        fileList.pop()
        this.ruleForm.list = ''
        this.$message.error(message)
      }
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      this.$message.error(err.msg)
      this.fullscreenLoading = false // 等待loading 关闭
    },
    computedMoreArticles() {
      if (this.recommendArticleStatus === '正在计算中!') {
        this.$message.info('正在计算，请稍等')
      } else if (this.recommendArticleStatus === '未计算!' || this.recommendArticleStatus === '计算完成!') {
        let params = {
          historyId: this.currentTask.id
        }
        initPushRecommendArticle(params).then((res) => {
          if (res && res.data.status === 0) {
            this.$message.info('开始计算，请稍等')
          } else {
            this.$message.error(res.data.message)
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
.issueArticle {
  // 新建微网刊文章
  .new-article-form {
    .el-form-item {
      width: auto !important;
      margin-bottom: 20px;
    }
    .el-form-item__label {
      display: inline-block !important;
    }
    .new-article-img-box {
      position: relative;
      overflow: hidden;
      .new-article-img {
        height: 200px;
        width: 200px;
        border: 1px solid #ddd;
        background: #f4f4f4;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .new-article-upload {
        .el-button {
          height: 200px;
          width: 200px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.issueArticle {
  .btn-box {
    display: flex;
    align-self: center;
  }
  .preview-img-dialog {
    width: 100%;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
