import request from './services/axios.js'
//邮件推送 -- 学者分析相关接口
//引用期刊分布
export function ReferenceMag(params) {
  return request({
    params,
    url: '/vas/statistics/referenceMag',
    method: 'get'
  })
}
//引证期刊分布
export function CitationMag(params) {
  return request({
    params,
    url: '/vas/statistics/citationMag',
    method: 'get'
  })
}
//机构发文统计
export function Institution(params) {
  return request({
    params,
    url: '/vas/statistics/institution',
    method: 'get'
  })
}
//引用机构分布
export function ReferenceInstitution(params) {
  return request({
    params,
    url: '/vas/statistics/referenceInstitution',
    method: 'get'
  })
}
//引证机构分布
export function CitationInstitution(params) {
  return request({
    params,
    url: '/vas/statistics/citationInstitution',
    method: 'get'
  })
}
// 目标用户概览
export function GetPushMagAuthorNumber(params) {
  return request({
    params,
    url: '/push/mag/history/author/getPushMagAuthorNumber',
    method: 'get'
  })
}
// 目标用户机构分析
export function GetPushMagAuthorOrg(params) {
  return request({
    params,
    url: '/push/mag/history/author/getPushMagAuthorOrg',
    method: 'get'
  })
}
// 目标用户期刊分析
export function GetPushMagAuthorMag(params) {
  return request({
    params,
    url: '/push/mag/history/author/getPushMagAuthorMag',
    method: 'get'
  })
}
// 任务推送目标人数算法来源分析
export function GetPushMagAuthorType(params) {
  return request({
    params,
    url: '/push/mag/history/author/getPushMagAuthorType',
    method: 'get'
  })
}
// 任务推送目标人数来源分析
export function GetPushMagAuthorSourceType(params) {
  return request({
    params,
    url: '/push/mag/history/author/getPushMagAuthorSourceType',
    method: 'get'
  })
}
// 任务推送论文栏目
export function GetColumn(params) {
  return request({
    params,
    url: '/vas/push/article/getColumn',
    method: 'get'
  })
}
// 任务推送目标人数栏目论文来源分析
export function GetPushMagAuthorTypeByColumn(params) {
  return request({
    params,
    url: '/push/mag/history/author/getColumnPushMagAuthorType',
    method: 'get'
  })
}
// 任务推送目标人数任务设置来源分析
export function GetPushMagAuthorSetCount(params) {
  return request({
    params,
    url: '/push/mag/history/author/getPushMagAuthorSetCount',
    method: 'get'
  })
}
// 获取历史推送版本
export function getSendVersion(params) {
  return request({
    params,
    url: '/vas/send/author/getSendVersion',
    method: 'get'
  })
}
