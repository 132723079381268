<!-- type2Template -->
<template>
  <div class="type2Template">
    <el-form :model="ruleForm" :rules="rules">
      <banner :ruleForm="ruleForm" :rules="rules"></banner>
      <!-- <openPublishPush v-if="configInfo.openPublishPush" :ruleForm="ruleForm"></openPublishPush> -->
      <!-- <openReferencePush v-if="configInfo.openReferencePush" :ruleForm="ruleForm"></openReferencePush> -->
      <div class="info-box">
        <div class="info-left">
          <specialContent :ruleForm="ruleForm" :rules="rules"></specialContent>
        </div>
        <div class="info-right ml-20">
          <magCover :ruleForm="ruleForm" :rules="rules"></magCover>
          <textBefore :ruleForm="ruleForm" :rules="rules" :showTab="false"></textBefore>
        </div>
      </div>
      <articleIndexStart :ruleForm="ruleForm" :rules="rules"></articleIndexStart>
    </el-form>
  </div>
</template>

<script>
import banner from '../modules/banner.vue'
import specialContent from '../modules/specialContent.vue'
import magCover from '../modules/magCover.vue'
import textBefore from '../modules/textBefore.vue'
import articleIndexStart from '../modules/articleIndexStart.vue'
import openPublishPush from '../modules/openPublishPush.vue'
import openReferencePush from '../modules/openReferencePush.vue'
export default {
  name: 'type2Template',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    },
    configInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { banner, specialContent, magCover, textBefore, articleIndexStart },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
