<!-- type3Template -->
<template>
  <div class="type3Template">
    <el-form :model="ruleForm" :rules="rules">
      <banner :ruleForm="ruleForm" :rules="rules" showResetBtn></banner>
      <textBefore :ruleForm="ruleForm" :rules="rules"></textBefore>
      <footerBox :ruleForm="ruleForm" :rules="rules" showResetBtn></footerBox>
      <textAfter :ruleForm="ruleForm" :rules="rules" infoLabel="说明文字："></textAfter>
    </el-form>
  </div>
</template>

<script>
import banner from '../modules/banner.vue'
import textBefore from '../modules/textBefore.vue'
import footerBox from '../modules/footerItem.vue'
import textAfter from '../modules/textAfter.vue'
export default {
  name: 'type3Template',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { banner, textBefore, footerBox, textAfter },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
