import { render, staticRenderFns } from "./textAfter.vue?vue&type=template&id=de3730d8&scoped=true"
import script from "./textAfter.vue?vue&type=script&lang=js"
export * from "./textAfter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de3730d8",
  null
  
)

export default component.exports