import request from './services/axios.js'
// 任务列表
export function GetPushTaskList(params) {
  return request({
    params,
    url: '/vas/push/task/pushTaskList',
    method: 'get'
  })
}
// 任务详情
export function GetTaskInfo(params) {
  return request({
    params,
    url: '/vas/push/task/getTaskInfo',
    method: 'get'
  })
}
// 文章点击量排行
export function GetArticleClickList(params) {
  return request({
    params,
    url: '/vas/push/task/articleClickRankList',
    method: 'get'
  })
}
// 学者活跃度排行
export function GetAuthorRankList(params) {
  return request({
    params,
    url: '/vas/send/author/getAuthorSumList',
    method: 'get'
  })
}
// 传播路径
export function GetDiffusePathList(params) {
  return request({
    params,
    url: '/vas/push/task/diffusePath',
    method: 'get'
  })
}
// 点击情况统计折线图
export function GetStatisticsByDay(params) {
  return request({
    params,
    url: '/vas/push/task/statisticsByDay',
    method: 'get'
  })
}
// 获取所有任务用户行为信息
export function GetAllTaskInfo(params) {
  return request({
    params,
    url: '/vas/push/task/getAllTaskInfo',
    method: 'get'
  })
}
// 单独推送页面验证码验证
export function Verify(params) {
  return request({
    params,
    url: '/vas/mag/verify',
    method: 'get'
  })
}
