<!-- type5Template -->
<template>
  <div class="type5Template">
    <el-form :model="ruleForm" :rules="rules">
      <textBeforeTitle :ruleForm="ruleForm" :rules="rules"></textBeforeTitle>
      <!-- <openPublishPush v-if="configInfo.openPublishPush" :ruleForm="ruleForm"></openPublishPush> -->
      <!-- <openReferencePush v-if="configInfo.openReferencePush" :ruleForm="ruleForm"></openReferencePush> -->
      <div class="info-box">
        <div class="info-left">
          <textBefore :ruleForm="ruleForm" :rules="rules" infoLabel="期刊介绍正文："></textBefore>
        </div>
        <div class="info-right ml-20">
          <magCover :ruleForm="ruleForm" :rules="rules"></magCover>
        </div>
      </div>
      <specialTopic :ruleForm="ruleForm" :rules="rules"></specialTopic>
      <specialContent :ruleForm="ruleForm" :rules="rules"></specialContent>
      <topic :ruleForm="ruleForm" :rules="rules"></topic>
    </el-form>
  </div>
</template>

<script>
import textBeforeTitle from '../modules/textBeforeTitle.vue'
import textBefore from '../modules/textBefore.vue'
import magCover from '../modules/magCover.vue'
import specialTopic from '../modules/specialTopic.vue'
import specialContent from '../modules/specialContent.vue'
import topic from '../modules/topic.vue'
import openPublishPush from '../modules/openPublishPush.vue'
import openReferencePush from '../modules/openReferencePush.vue'
export default {
  name: 'type5Template',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    },
    configInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { textBeforeTitle, textBefore, magCover, specialTopic, specialContent, topic },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
